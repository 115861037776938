<template>
  <div>
    <!-- for 賣家 -->
    <div class="select-stores pointer" v-if="forWhitchOne === 'seller'">
      <template v-for="data in ownerStores.data" :key="data.storeId">
        <div
          class="select"
          @click.stop="showStoreList"
          v-if="currentStoreId === data.storeId"
        >
          {{ data.store.name }}
        </div>
      </template>
      <ul class="select-stores-list d-none">
        <template v-for="data in ownerStores.data" :key="data.storeId">
          <li
            class="text-break"
            :class="{ active: currentStoreId === data.storeId }"
            v-if="!data.store.deleted && data.is_inSubscription"
            @click="currentStoreId = data.storeId"
          >
            {{ data.store.name }}
          </li>
        </template>
      </ul>
    </div>
    <!-- todo for 顧客 -->
    <div class="select-stores pointer" v-if="forWhitchOne === 'customer'">
      <template v-for="data in customerStores.data" :key="data.storeId">
        <div
          class="select"
          @click.stop="showStoreList"
          v-if="currentStoreId === data.storeId"
        >
          {{ data.store.name }}
        </div>
      </template>
      <ul class="select-stores-list d-none">
        <template v-for="data in customerStores.data" :key="data.storeId">
          <li
            class="text-break"
            :class="{ active: currentStoreId === data.storeId }"
            v-if="data.verify"
            @click="currentStoreId = data.storeId"
          >
            {{ data.store.name }}
          </li>
        </template>
      </ul>
    </div>
    <!-- todo for fb -->
    <div class="select-stores pointer" v-if="forWhitchOne === 'fb'">
      <template v-for="data in fbStores.data" :key="data.storeId">
        <div
          class="select"
          @click.stop="showStoreList"
          v-if="currentStoreId === data.storeId"
        >
          {{ data.store.name }}
        </div>
      </template>
      <ul class="select-stores-list d-none">
        <template v-for="data in fbStores.data" :key="data.storeId">
          <li
            class="text-break"
            :class="{ active: currentStoreId === data.storeId }"
            v-if="data.verify"
            @click="currentStoreId = data.storeId"
          >
            {{ data.store.name }}
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
// vuex
import { mapState } from "vuex";

export default {
  data() {
    return {
      forWhitchOne: '',
      currentStore: null,
      currentStoreId: 0,
    }
  },
  created() {
    this.initialization()
  },
  computed: {
    ...mapState('StoreList', {
      ownerStores: state => state.ownerStores,
      customerStores: state => state.customerStores,
      fbStores: state => state.fbStores
    })
  },
  watch: {
    currentStoreId(val, oldVal) {
      // 判斷有沒有舊值，沒有的話代表第一次進入
      if (oldVal) {
        this.$router.replace({
          params: {
            storeId: val
          }
        })
      }
    },
  },
  methods: {
    initialization() {
      this.currentStoreId = parseInt(this.$route.params.storeId)
      // 檢查是在顧客還是在賣家模組
      this.$route.matched.some(item => {
        if (item.name === 'CustomerSideBar') {
          this.forWhitchOne = 'customer'
          return true
        } else if (item.name === 'SellerSideBar') {
          this.forWhitchOne = 'seller'
          return true
        } else if (item.name === 'FbSideBar') {
          this.forWhitchOne = 'fb'
          return true
        }
      })
    },
    showStoreList() {
      $('.select-stores-list').removeClass('d-none')
    },
  },
}
</script>